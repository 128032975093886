import React from "react";
import loadable from "@loadable/component";

const PageContainer = loadable(() => import("@templates/DefaultPageContainer"));
const PageHero = loadable(() => import("@organisms/PageHero"));
const CaseStudyList = loadable(() => import("@organisms/CaseStudyList"));
const PageBuilder = loadable(() => import("@templates/PageBuilder"));
const EopCta = loadable(() => import("@organisms/EopCta"));
const NewsletterSignup = loadable(() => import("@organisms/NewsletterSignup"));

const CaseStudyIndexPage = ({ data }) => {
  const { hero, pageBuilder, caseStudies, theme } = data;
  return (
    <PageContainer overflow>
      {!!hero.heading && (
        <PageHero
          backLink={{ text: "Impact", url: "/impact" }}
          color={theme}
          watermark
          noHeight
          {...hero}
        />
      )}
      <CaseStudyList items={caseStudies} />
      <PageBuilder blocks={pageBuilder} hasHero={!!hero.heading} />
      <EopCta />
      <NewsletterSignup />
    </PageContainer>
  );
};

export default CaseStudyIndexPage;
