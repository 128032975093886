/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import PageTemplate from "@pageTemplates/CaseStudyIndexPage";
import resolvePageBuilder from "@dataResolvers/resolvePageBuilder";
import resolveImage from "@dataResolvers/resolveImage";
import resolveCaseStudy from "@dataResolvers/resolveCaseStudy";
// eslint-disable-next-line no-unused-vars
import PageBuilderFragment from "@fragments/PageBuilderFragment";
import QueryContainer, { HeadTemplate } from "../QueryContainer";

export const query = graphql`
  query CaseStudyIndexQuery {
    craft {
      entry(section: "caseStudyIndex") {
        ... on Craft_caseStudyIndex_caseStudyIndex_Entry {
          # hero
          heroHeading: heading0
          heroDescriptor: descriptor0
          heroImage: image0 {
            ...ImageFragment
          }
          # pageBuilder
          pageBuilder {
            ...PageBuilderFragment
          }
          # meta
          title
          url
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          theme
        }
      }
      entries(section: "caseStudies") {
        ... on Craft_caseStudies_default_Entry {
          uid
          url: uri
          type: sectionHandle
          title
          heading: heading0
          metaDescription
          metaImage {
            ...ImageFragment
          }
          modules: moduleList {
            ... on Craft_modules_default_Entry {
              uid
              title
            }
          }
          audiences {
            title
            uid
          }
        }
      }
    }
  }
`;

const dataResolver = ({ entry, entries }) => {
  const {
    // hero
    heroHeading,
    heroDescriptor,
    heroImage,
    theme,
    // pageBuilder
    pageBuilder,
    // meta
    url,
    title,
    metaTitle,
    metaDescription,
    metaImage,
  } = entry;

  const caseStudies = entries.map(resolveCaseStudy);

  return {
    hero: {
      heading: heroHeading,
      descriptor: heroDescriptor,
      image: resolveImage(heroImage),
      theme,
    },
    caseStudies,
    pageBuilder: resolvePageBuilder(pageBuilder),
    meta: {
      url,
      title,
      metaPageTitle: metaTitle,
      metaDescription,
      metaFeaturedImage: resolveImage(metaImage),
    },
  };
};

export const Head = ({ data, ...rest }) => {
  const { craft } = data;
  const resolvedData = dataResolver(craft);
  return <HeadTemplate {...rest} data={resolvedData} />;
};

const Page = props => (
  <QueryContainer
    template={PageTemplate}
    dataResolver={dataResolver}
    queryName="CaseStudyIndexQuery"
    {...props}
  />
);

export default Page;
